import React from 'react'
import '../css/doctors.css'
const Doctors = () => {
  return (
    
      <div>
        <div className='section-text'>
          <h1 className='section-heading blue'>Meet Our Doctors</h1>
          <h3 className='section-subheading blue'>Our Expert Medical Team</h3>
          <p className='section-paragraph blue'>
            Get to know the dedicated professionals who are committed to
            providing personalized care and expertise to every patient
          </p>
        </div>
        <div className='doctors-content'>
          <div className='doctors-grid'>
            <div className='doctors-profile'>
              <img src='/img/doc1 1.png' alt='' />
              <h1 className='profile-name'>Dr. Alan Smith</h1>
              <h3 className='profile-detail'>M.D. of Medicine, MRCP (USA)</h3>
              <p className='profile-position'>Head of Physician</p>
            </div>
            <div className='doctors-profile'>
              <img src='\img\doc2 1.png' alt='doc' />
              <h1 className='profile-name'>Dr. Susan Edit</h1>
              <h3 className='profile-detail'>Ophthalmologist , DCRT (GR)</h3>
              <p className='profile-position'>Head of Ophtamology</p>
            </div>
            <div className='doctors-profile'>
              <img src='\img\doc3 1.png' alt='doc' />
              <h1 className='profile-name'>Dr. John Rock</h1>
              <h3 className='profile-detail'>Neurology, MRCP (UK)</h3>
              <p className='profile-position'>Head of Neurology</p>
            </div>
            <div className='doctors-profile'>
              <img src='\img\doc4 1.png' alt='' />
              <h1 className='profile-name'>Dr. Obiosa  Ike</h1>
              <h3 className='profile-detail'>Cardiologist, MRCP (UK) (USA)</h3>
              <p className='profile-position'>Chief surgeons</p>
            </div>
          </div>
        </div>
      </div>
    
  )
}

export default Doctors
