import React from 'react'
import '../css/about.css'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <div className='about-container  '>
      <div className='about-content'>
        <img
          src='/img/pic1 (11).jpg'
          alt='aboutimage'
        />
        <div className='about-text'>
          <h1>Welcome To AH Specialist Hospital</h1>
          <p>
            At AH specialist hospital we are dedicated to providing
            compassionate and high-quality healthcare services to our community.
            With a commitment to excellence and a focus on patient-centered
            care, we strive to be your trusted healthcare partner."
          </p>
        </div>
      </div>
      <div>
        <Link to='/about' className='a-btn about-btn'>
          Read more
        </Link>
      </div>
    </div>
  )
}

export default About
