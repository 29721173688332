import React, { useEffect } from 'react'
import HeroSection from './HeroSection'
// import CenterGrid from './CenterGrid'
import About from './About'
import Service from './Service'
import Doctors from './Doctors'
import Testimonials from './Testimonials'
import Blog from './Blog'
import Appointment from './Appointment'
import Gallery from './Gallery'
import Ambulance from './Ambulance'
import '../css/scroll.css'
import '../css/loading.css'
const Home = () => {

useEffect(() => {
  const reveals = document.querySelectorAll('.reveal')
  const options = {
    threshold: 0.1,
  }

  const revealObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active')
        revealObserver.unobserve(entry.target)
      }
    })
  }, options)

  reveals.forEach((reveal) => {
    revealObserver.observe(reveal)
  })

  return () => {
    reveals.forEach((reveal) => {
      revealObserver.unobserve(reveal)
    })
  }
}, [])
  return (
    <div>
      <section className='hero-section '>
        <div className='reveal'>
          <HeroSection />
        </div>
      </section>
      <section className='ambulance-section'>
        <div className='reveal'>
          <Ambulance />
        </div>
      </section>
      <section className='about-section'>
        <div className='reveal'>
          <About />
        </div>
      </section>
      <section className='service-section '>
        <div className='reveal'>
          <Service />
        </div>
      </section>
      <section className='gallery-section '>
        <div className='reveal'>
          <Gallery />
        </div>
      </section>
      {/* <section className='doctors-section '>
        <div className='reveal'>
          <Doctors />
        </div>
      </section> */}
      {/* <section className='testimony-section'>
        <div className='reveal'>
          <Testimonials />
        </div>
      </section> */}
      <section className='blog-section '>
        <div className='reveal'>
          <Blog />
        </div>
      </section>
      <section className='appointment-section'>
        <div className='reveal'>
          <Appointment />
        </div>
      </section>
    </div>
  )
}

export default Home
