    import React from 'react'
    import '../css/aboutsection.css'
import Appointment from '../components/Appointment'
    const AboutSection = () => {
      return (
        <section className='aboutsection-section'>
          <section className='top'>
            <div className='aboutsection-container-top'>
              <div className='aboutsection-content'>
                <div className='aboutsection-text'>
                  <h1 className='main-heading'>
                    Welcome To AH Specialist Hospital
                  </h1>
                  <p>
                    At AH specialist hospital we are dedicated to providing
                    compassionate and high-quality healthcare services to our
                    community. With a commitment to excellence and a focus on
                    patient-centered care, we strive to be your trusted
                    healthcare partner."
                  </p>
                </div>
                <div className='side'>
                  <div className='side-one'>
                    <h2 className='intro-heading'>Our Mission</h2>
                    <p>
                      To enhance the well-being of our community through care
                      and innovation, by delivering high quality care to our
                      clients with compassion, integrity, and respect. We aim to
                      exceed our patients' expectations and create a positive
                      impact on the communities we serve.
                    </p>
                  </div>
                  <div className='side-two'>
                    <h2>Our Vision</h2>
                    <p>
                      Advancing Medicine, Touching lives, Making a Difference
                      through:
                      <ul>
                        <li>innovative health care practices.</li>
                        <li>
                          excellent health outcomes supported by evidence based
                          practices.
                        </li>
                        <li>
                          enforcing a culture of equity in health care delivery.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className='history'>
                  <h2>Our History</h2>
                  <p>
                    AH specialist hospital was founded in 2022 with the vision
                    of creating a center of excellence in healthcare. Since
                    then, we have grown to become a leading healthcare provider,
                    offering comprehensive medical services to patients of all
                    ages."
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className='middle'>
            <div className='aboutsection-container-bottom'>
              <div className='team'>
                {/* <h2>Meet Our Team</h2> */}
                {/* <div className='team-list'>
                  <div className='team-item'>
                    <div className='img'></div>
                    <h5>Susan Shaft</h5>
                    <span>Head of clinical service</span>
                  </div>
                  <div className='team-item'>
                    <div className='img'></div>
                    <h5>Susan Shaft</h5>
                    <span>Head of clinical service</span>
                  </div>
                  <div className='team-item'>
                    <div className='img'></div>
                    <h5>Susan Shaft</h5>
                    <span>Head of clinical service</span>
                  </div>
                  <div className='team-item'>
                    <div className='img'></div>
                    <h5>Susan Shaft</h5>
                    <span>Head of clinical service</span>
                  </div>
                </div> */}
              </div>
            </div>

            <div className='side'>
              <div className='side-one'>
                <h2>Facilities</h2>
                <p>
                  Our state-of-the-art facilities are equipped with the latest
                  technology and resources to provide advanced diagnostic and
                  treatment services. From our modern operating rooms to our
                  comfortable patient rooms, we strive to create a healing
                  environment where patients feel safe and cared for.
                </p>
              </div>
              <div className='side-two'>
                <h2>Community Involvement</h2>
                <p>
                  At AH specialist hospital, we believe in giving back to the
                  communities we serve. Through partnerships with local
                  organizations and outreach programs, we are actively involved
                  in promoting health and wellness initiatives and addressing
                  the unique needs of our community.
                </p>
              </div>
            </div>
            <div className='award'>
              <h2>Accreditations and Award</h2>
              <p>
                We are proud to be accredited by the Health Facilities
                Monitoring and Accreditation Agency (HEFAMAA) and to have
                received recognition for our commitment to quality and safety.
                These accolades reflect our dedication to delivering excellence
                in healthcare
              </p>
            </div>
          </section>
          <section className='top'>
            <div className='aboutsection-container-top'>
              <h2 className='why-c-heading'>Why Choose us</h2>

              <div className='why-c'>
                <div className='why-c-items'>
                  <h4>Ambulance Service</h4>
                  <img src='\img\ambulance-logo.jpg' alt='ambulance-logo' />
                </div>
                <div className='why-c-items'>
                  <h4>24/7 service</h4>
                  <img src='\img\24hour.png' alt='24h-logo' />
                </div>
                <div className='why-c-items'>
                  <h4>Advance Technologies</h4>
                  <img src='\img\advance-tech.jpg' alt='Advance-tech' />
                </div>
                <div className='why-c-items'>
                  <h4>Pharmancy</h4>
                  <img src='/img/pharmacy_169837.png' alt='Pharmancy' />
                </div>
                <div className='why-c-items'>
                  <h4>Laboratory</h4>
                  <img src='/img/laboratory_1682000.png' alt='lab' />
                </div>
              </div>
            </div>
          </section>
        </section>
      )
    }
    
    export default AboutSection
    