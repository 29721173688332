import React from 'react'
import { Link } from 'react-router-dom'
import '../css/ambulance.css'
const Ambulance = () => {
  
  return (
    <div className='ambulance-container'>
      <div className='section-text'>
        <h1 className='header'>Need An Ambulance</h1>
      </div>
      <div className='ambulance-img'>
        <img src='/img/ambulance-car.jpg' alt='ambulance' />
      </div>
      <p className='header-paragraph'>
        Book our free ambulance service for prompt responses to any emergencies
        at zero to no cost
      </p>
      <div className='ambu-footer'>
        <Link to='/ambulance'>
          <button className='ambu-btn'>Book An Ambulance</button>
        </Link>
      </div>
    </div>
  )
}

export default Ambulance
