import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const AmbulancePage = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    reason: '',
    email: '',
    mobile: '',
    address: '',
    dateNeeded: null,
  })
  const [confirmation, setConfirmation] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      formData.name === '' ||
      !formData.reason ||
      !formData.email ||
      !formData.dateNeeded ||
      !formData.mobile ||
      !formData.address
    ) {
      setConfirmation(
        <div id='success'>
          <p>Please fill in all required fields.</p>
        </div>
      )
      return
    }
    setIsLoading(
      <div className='overlay'>
        <div className='loader-dev'>
          <div class='lds-heart'>
            <div></div>
          </div>
        </div>
      </div>
    )
    try {
      const response = await fetch(
        
        'https://backend.ahspecialisthospital.com/api/ambulance',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      )
      if (response.ok) {
        setFormData({
          name: '',
          reason: '',
          mobile: '',
          address: '',
          dateNeeded: null,
        })
        const { name } = formData
        const bookingDetails = name
        navigate('/ambulance-confirmation', { state: { bookingDetails } })
      setIsLoading(null)
      } else {
        setConfirmation(
          <div id='success'>
            <p>Booking failed. Please try again.</p>
          </div>
        )
      }
    } catch (error) {
      setConfirmation(
        <div id='success'>
          <p>Network error. Please try again.</p>
        </div>
      )
    }
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }

  const handleDateChange = (date, id) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: date,
    }))
    console.log(date)
  }

  return (
    <section className='ambulancepage-section'>
      <div className='section-container'>
        <div className='form-section'>
          <form action='' onSubmit={handleSubmit}>
            <div>
              <h4>
                Please fill out the form below to book our free ambulance
                service
              </h4>
              {isLoading}
              {confirmation}
              <div className='ambulance-form'>
                <div className='ambu-form'>
                  <label htmlFor='name'>Name</label>
                  <input
                    type='text'
                    placeholder='Eg: John Emon'
                    id='name'
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='ambu-form'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    placeholder='Eg: email@gmail.com'
                    id='email'
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='ambu-form'>
                  <label htmlFor='mobile'>Phone Number</label>
                  <input
                    type='text'
                    placeholder='0800000000'
                    id='mobile'
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='ambu-form'>
                  <label htmlFor='address'>Address</label>
                  <input
                    id='address'
                    type='text'
                    placeholder='Eg: 60 Sam Brown'
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='ambu-form'>
                  <label htmlFor='dateNeeded'>Date Needed</label>
                  <DatePicker
                    id='dateNeeded'
                    selected={formData.dateNeeded}
                    onChange={(date) => handleDateChange(date, 'dateNeeded')}
                    className='form-control'
                    placeholderText='MM/DD/YYYY'
                  />
                </div>
                <div className='ambu-form'>
                  <label htmlFor='reason'>Reason For Booking</label>
                  <select
                    className='select'
                    id='reason'
                    value={formData.reason}
                    onChange={handleInputChange}
                  >
                    <option defaultValue='selected'>
                      choose Reason For Booking
                    </option>
                    <option>Extremely Urgent Health Issues</option>
                    <option>Accident</option>
                    <option>Maternity Service / Delivery</option>
                    <option>General Check Up</option>
                    <option>Complications</option>
                    <option>Others</option>
                  </select>
                </div>
              </div>

              <div className='ambup-footer'>
                <button className='ambup-btn'>Book An Ambulance</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default AmbulancePage
