import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../css/contact.css'
import { Link } from 'react-router-dom'

const Contact = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    subject: '',
    message: '',
  })

  const [confirmation, setConfirmation] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      formData.fname === '' ||
      !formData.lname ||
      !formData.email ||
      !formData.subject ||
      !formData.message
    ) {
      setConfirmation(
        <div id='success'>
          <p>Please fill in all required fields</p>
        </div>
      )
      return
    }

    setIsLoading(true)

    try {
      const response = await fetch(
        'http://localhost:2020/api/contact',
        // 'https://backend.ahspecialisthospital.com/api/contact',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      )

      if (response.ok) {
        setFormData({
          lname: '',
          fname: '',
          email: '',
          subject: '',
          message: '',
        })
        const { lname, fname } = formData
        const bookingDetails = fname + ' ' + lname
        navigate('/contact-confirmation', { state: { bookingDetails } })
        setIsLoading(null)
      } else {
        setConfirmation(
          <div id='success'>
            <p>message sending failed. Please try again later.</p>
          </div>
        )
      }
    } catch (error) {
      setConfirmation(
        <div id='success'>
          <p>
            An error occurred on the server. Please try again later or use other
            contact medium.
          </p>
        </div>
      )
    }
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }
if (isLoading){
  return (
    <div className='overlay'>
      <div className='loader-dev'>
        <div class='lds-heart'>
          <div></div>
        </div>
      </div>
    </div>
  )
}
  return (
    <section className='contact-section '>
      <div>
        <section className='top-section'>
          <div className='contact-container'>
            <h1>Get In Touch With Us </h1>
          </div>
        </section>
        <section className='middle-section'>
          <div className='middle-container'>
            <div className='contact-item'>
              <img src='/img/🦆 icon _location pin_ (1).png' alt='' />
              <span>54 Audu Bako Way, Nassarawa, Kano City</span>
            </div>
            <div className='contact-item'>
              <img
                src='\img\phone_callback_FILL0_wght400_GRAD0_opsz24 1.png'
                alt=''
              />
              <span>
                <Link to='tel:+2348034140040' className='link'>
                  +2348034140040
                </Link>
              </span>
            </div>
            <div className='contact-item'>
              <img
                src='\img\mail_FILL0_wght400_GRAD0_opsz24 (1) 1 (1).png'
                alt=''
              />
              <span>
                <Link to='mailto:ahshospital@gmail.com' className='link'>
                  ahshospital@gmail.com
                </Link>
              </span>
            </div>
          </div>
        </section>
        <section className='contact-from'>
          <div className='form-container'>
            <form action='' className='form' onSubmit={handleSubmit}>
              <h1>Send Us A Message</h1>
              {isLoading}
              {confirmation}
              <div className='form-input'>
                <input
                  type='text'
                  placeholder='First Name'
                  id='fname'
                  onChange={handleInputChange}
                  value={formData.fname}
                />
                <input
                  type='text'
                  placeholder='Last Name'
                  id='lname'
                  onChange={handleInputChange}
                  value={formData.lname}
                />
                <input
                  type='email'
                  placeholder='Email'
                  id='email'
                  onChange={handleInputChange}
                  value={formData.email}
                />
                <input
                  type='text'
                  placeholder='Subject'
                  id='subject'
                  onChange={handleInputChange}
                  value={formData.subject}
                />
                <input
                  type='text'
                  placeholder='Message'
                  id='message'
                  onChange={handleInputChange}
                  value={formData.message}
                />
                <div className='btn'>
                  <button type='submit'>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </section>
  )
}

export default Contact
