import React from 'react'
import '../css/hero.css'
import { Link } from 'react-router-dom'
const HeroSection = () => {
  return (
    <div className='hero-container'>
      <div className='hero-content'>
        <div className='hero-text'>
          <h1>
            Empowering Healthier <br />
            Lives Every Day
          </h1>
          <p>
            Discover personalized care tailored for your needs. Together, we're
            dedicated to your journey towards better health
          </p>
        </div>
        <img
          src='\img\nurse.png'
          alt='doc1'
        />
      </div>
      <Link to='/appointment' className='hero-btn'>
        Book Appointment
      </Link>
    </div>
  )
}

export default HeroSection
