import React from 'react'
import '../css/servicepage.css'
import Appointment from '../components/Appointment'
import {Link} from 'react-router-dom'
const ServicePage = () => {
  return (
    <section className='servicepage-section'>
      <div className='service-page'>
        <div className='servicepage-item'>
          <h1 id='si-edit'>Pharmacy</h1>
          <p>
            Access quality medications and expert pharmaceutical care at our
            pharmacy.We are dedicated to providing good service and guidance,
            ensuring you receive the right medications and support for your
            health needs
          </p>
        </div>
        <div className='servicepage-item'>
          <h1>General Medical Consultation</h1>
          <p>
            Receive personalized medical advice and treatment plans from our
            team of experienced physicians, dedicated to addressing your health
            concerns with care and expertise.
          </p>
        </div>
        <div className='servicepage-item'>
          <h1>Obstetrics And Gynaecology</h1>
          <p>
            Embark on the journey of motherhood with confidence and care.
            Receive specialized care for women's health issues, from routine
            screenings to complex gynecological procedures.
          </p>
        </div>
        <div className='servicepage-item'>
          <h1 id='edit-1'>Surgery</h1>
          <p>
            Our surgical department is dedicated to providing comprehensive
            services, from routine procedures to complex surgeries.we are
            committed to ensuring your comfort, safety, and successful recovery
            throughout your surgical journey.
          </p>
        </div>
        <div className='servicepage-item'>
          <h1 id='edit-2'>Imagery</h1>
          <p>
            Access advanced imagery services for accurate diagnosis and
            treatment planning, facilitated by our state-of-the-art imagery
            department.
          </p>
        </div>
        <div className='servicepage-item'>
          <h1>Laboratory and Diagnosis</h1>
          <p>
            Access reliable and timely diagnostic services through our advanced
            laboratory facilities, supporting accurate diagnosis and effective
            treatment plans.
          </p>
        </div>
        <div className='servicepage-item'>
          <h1>Dental Care</h1>
          <p>
            Achieve optimal oral health with our range of dental services,
            delivered by skilled professionals dedicated to preserving your
            smile and overall wellness.
          </p>
        </div>
        <div className='servicepage-item'>
          <h1>Eye-care Consultation</h1>
          <p>
            Ensure optimal vision and eye health with our comprehensive eye-care
            services, delivered by skilled optometrists and ophthalmologists
            committed to preserving your sight
          </p>
        </div>
      </div>
      <div className='form-footer'>
        <Link to='/appointment'>
          <button className='form-btn'>Book Appointment</button>
        </Link>
      </div>
      {/* <Appointment /> */}
    </section>
  )
}

export default ServicePage
