import React from 'react'
import '../css/footer.css'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <section className='footer-section '>
      <div className='footer-container'>
        <div className='footer-items-top'>
          <img className='footer-logo' src='/img/logo-bg.png' alt='logo' />
          <div className='footer-socials'>
            <img src='/img/f-insta.png' alt='' />
            <img src='/img/f-x.png' alt='' />
            <img src='/img/f-fb.png' alt='' />
          </div>
        </div>
        <div className='footer-items'>
          <h3>Site Map</h3>
          <h5>
            <Link to='/'>Home</Link>
          </h5>
          <h5>
            <Link to='/about'>About</Link>
          </h5>
          <h5>
            <Link to='/service'>Service</Link>
          </h5>
          <h5>
            <Link to='/doctors'>Doctors</Link>
          </h5>
          <h5>
            <Link to='/blog'>Blog</Link>
          </h5>
          <h5>
            <Link to='/contact'>Contact</Link>
          </h5>
        </div>
        <div className='footer-items'>
          <h3>Contact</h3>
          <div className='footer-address'>
            <img
              src='\img\location.png'
              alt=''
            />
            <span>54 Audu Bako Way, Nassarawa, Kano City</span>
          </div>
          <div className='footer-mail'>
            <img
              src='\img\mail_FILL0_wght400_GRAD0_opsz24 (1) 1 (1).png'
              alt=''
            />
            <span>
              <Link to='mailto: info@ahshospital.com' className='link'>
                info@ahshospital.com
              </Link>
            </span>
          </div>
          <div className='footer-mail'>
            <img
              src='\img\mail_FILL0_wght400_GRAD0_opsz24 (1) 1 (1).png'
              alt=''
            />
            <span>
              <Link to='mailto:ahshospital@gmail.com' className='link'>
                ahshospital@gmail.com
              </Link>
            </span>
          </div>
          <div className='footer-mail'>
            <img
              src='\img\phone_callback_FILL0_wght400_GRAD0_opsz24 1.png'
              alt=''
            />
            <span>
              <Link to='tel:+2348034140040' className='link'>
                +2348034140040
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className='copy-right'>
        2024 AH Specialist Hospital, All Right Reserved
      </div>
    </section>
  )
}

export default Footer
