import React from 'react'
import '../css/gallary.css'

const Gallery = () => {
  return (
    <div>
      <div className='section-text'>
        <h1 className='section-heading'>Explore Our Hospital</h1>
        <h3 className='section-subheading'>
          Take a glance at our state-of-the-art facilities
        </h3>
      </div>
      <div className='gallery-container'>
        <div
          id='carouselExampleRide'
          class='carousel slide'
          data-bs-ride='carousel'
        >
          <div class='carousel-inner'>
            <div class='carousel-item active'>
              <img
                src='\img\pic1 (4).jpg'
                alt='zaytun-night'
                className='c-img'
              />
              <div className='carousel-caption d-none d-md-block'>
                {/* <h5>well equipied surgry room</h5> */}
              </div>
            </div>
            <div class='carousel-item active'>
              <img src='\img\pic1 (1).jpg' alt='hospital' className='c-img' />
              <div className='carousel-caption d-none d-md-block'>
                {/* <h5>
                  state-of-the-art facilities full support and morden equipments
                </h5> */}
              </div>
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (5).jpg' alt='hospital' className='c-img' />
              <div className='carousel-caption d-none d-md-block'>
                {/* <h5>
                  state-of-the-art facilities full support and morden equipments
                </h5> */}
              </div>
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (14).jpg' alt='hospital' className='c-img' />
              <div className='carousel-caption d-none d-md-block'>
                {/* <h5>
                  state-of-the-art facilities full support and morden equipments
                </h5> */}
              </div>
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (16).jpg' alt='hospital' className='c-img' />
              <div className='carousel-caption d-none d-md-block'>
                {/* <h5>
                  state-of-the-art facilities full support and morden equipments
                </h5> */}
              </div>
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (7).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (18).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (9).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (10).jpg' alt='hospital' className='c-img' />
            </div>
            
            <div class='carousel-item'>
              <img src='\img\pic1 (12).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (13).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (15).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (17).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (19).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (20).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (21).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (23).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (22).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (24).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (25).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (26).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (27).jpg' alt='hospital' className='c-img' />
            </div>
            <div class='carousel-item'>
              <img src='\img\pic1 (28).jpg' alt='hospital' className='c-img' />
            </div>
          </div>
          <button
            class='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleRide'
            data-bs-slide='prev'
          >
            <span class='carousel-control-prev-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Previous</span>
          </button>
          <button
            class='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleRide'
            data-bs-slide='next'
          >
            <span class='carousel-control-next-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Gallery
