import React from 'react'
import { Link } from 'react-router-dom'
import '../css/navbar.css'

const Navbar = () => {
  return (
    <section className='navigation'>
      <div className='container'>
        <div className='navbar2'>
          <span className='call'>Call +2348034140040</span>
          <div className='nav2'>
            <img src='/img/fb.png' alt='fb' />
            <img src='/img/tweet.png' alt='tweet' />
            <img src='/img/insta.png' alt='insta' />
          </div>
        </div>
        <div className='container nav-section'>
          <nav class='navbar navbar-expand-lg'>
            <div class='container-fluid'>
              <Link class='navbar-brand' href='#'>
                <div className='logo'>
                  <img src='/img/logo-bg.png' alt='logo' />
                </div>
              </Link>
              <button
                class='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarNav'
                aria-controls='navbarNav'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span class='navbar-toggler-icon'></span>
              </button>
              <div class='collapse navbar-collapse' id='navbarNav'>
                <ul class='navbar-nav'>
                  <li class='nav-item'>
                    <Link to='/' className='nav-link'>
                      Home
                    </Link>
                  </li>
                  <li class='nav-item'>
                    <Link to='/about' className='nav-link'>
                      About
                    </Link>
                  </li>
                  <li class='nav-item'>
                    <Link to='/service' className='nav-link'>
                      Service
                    </Link>
                  </li>
                  <li class='nav-item'>
                    <Link to='/contact' className='nav-link'>
                      Contact
                    </Link>
                  </li>

                  {/* <li class='nav-item'>
                    <Link to='/doctors' className='nav-link'>
                      Doctors
                    </Link>
                  </li> */}
                  <div class='nav-item'>
                    <Link to='/appointment'>
                      <div className='nav-btn'>Book Appointment</div>
                    </Link>
                  </div>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default Navbar
