import react from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import Navbar from './components/Navbar'
import Doctors from './components/Doctors'
import Footer from './components/Footer'
import Blog from './components/Blog'
import Testimonials from './components/Testimonials'
import Contact from './components/Contact'
import AboutSection from './pages/AboutSection'
import ServicePage from './pages/ServicePage'
import AmbulancePage from './pages/AmbulancePage'
import './js/scroll'
import AppointmentPage from './pages/AppointmentPage'
import ConfirmationPage from './components/ConfirmationPage'
import ContactComfirmation from './components/ContactComfirmation'
import AmbulanceConfirmation from './components/AmbulanceConfirmation'
import TestimonialForm from './components/TestimonialForm'
function App() {
  return (
    <Router>
      <div>
        <Navbar />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutSection />} />
          <Route path='/doctors' element={<Doctors />} />
          <Route path='/service' element={<ServicePage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/appointment' element={<AppointmentPage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/ambulance' element={<AmbulancePage />} />
          <Route path='/confirmation' element={<ConfirmationPage />} />
          <Route path='/contact-confirmation' element={<ContactComfirmation />} />
          <Route path='/ambulance-confirmation' element={<AmbulanceConfirmation />} />
          <Route path='/testimonial-form' element={<TestimonialForm />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
