import React from 'react'
import '../css/testimonial.css'
const Testimonials = () => {
  return (
    <div>
      <div className='section-text'>
        <h1 className='section-heading'>Testimonials</h1>
        <h3 className='section-subheading'>What Our Patient Says</h3>
      </div>
      <div className='testimony-container'>
        <div
          id='carouselExampleInterval'
          class='carousel slide'
          data-bs-ride='carousel'
        >
          <div class='carousel-inner'>
            <div class='carousel-item active' data-bs-interval='10000'>
              <div className='item'>
                <img
                  className='d-block'
                  src='/img/avarer.png'
                  alt='patient-img'
                />
                <p>
                  “I want to express my appreciation for a very finely run and
                  professional facility. Doctors always encouraged to ask
                  questions.”
                </p>
              </div>
              <div className='testi-footer'>
                <span>Robert Davis</span>
                <div className='star'>
                  <img src='/img/star-7207.png' alt='star-rating' />
                  <img src='/img/star-7207.png' alt='star-rating' />
                  <img src='/img/star-7207.png' alt='star-rating' />
                  <img src='/img/star-7207.png' alt='star-rating' />
                  <img src='/img/star-7207.png' alt='star-rating' />
                </div>
              </div>
            </div>
            <div class='carousel-item' data-bs-interval='2000'>
              <div className='item'>
                <img
                  className='d-block'
                  src='/img/avarer.png'
                  alt='First slide'
                />
                <p>
                  “I want to express my appreciation for a very finely run and
                  professional facility. Doctors always encouraged to ask
                  questions.”
                </p>
              </div>
              <div className='testi-footer'>
                <span>welcome-main </span>
                <div className='star'>
                  <img src='/img/star-7207.png' alt='' />
                  <img src='/img/star-7207.png' alt='' />
                  <img src='/img/star-7207.png' alt='' />
                  <img src='/img/star-7207.png' alt='' />
                  <img src='/img/star-7207.png' alt='' />
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div className='carousel-item'>
                <div className='item'>
                  <img
                    className='d-block'
                    src='/img/avarer.png'
                    alt='First slide'
                  />
                  <p>
                    “I want to express my appreciation for a very finely run and
                    professional facility. Doctors always encouraged to ask
                    questions.”
                  </p>
                </div>
                <div className='testi-footer'>
                  <span>steven apple </span>
                  <div className='star'>
                    <img src='/img/star-7207.png' alt='' />
                    <img src='/img/star-7207.png' alt='' />
                    <img src='/img/star-7207.png' alt='' />
                    <img src='/img/star-7207.png' alt='' />
                    <img src='/img/star-7207.png' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleInterval'
            data-bs-slide='prev'
          >
            <span class='carousel-control-prev-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Previous</span>
          </button>
          <button
            class='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleInterval'
            data-bs-slide='next'
          >
            <span class='carousel-control-next-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
