import React from 'react'
import { Link } from 'react-router-dom'
import Appointment from '../components/Appointment'
import '../css/ambulance.css'
const AppointmentPage = () => {
  return (
    <section className='appointmentpage-section'>
      <Appointment />
      <div className='call-div'>
        <h2>Emergency</h2>
        <h4>
          In case Of Emergency, Call Our Hot Line For Appointment Booking Or
          Ambulance Service
        </h4>
        <div className='form-foot'>
          <img src='\img\phone.png' alt='' />
          <span>
           
            <Link to='tel:+2348034140040'>
              +2348034140040
            </Link>
          </span>
        </div>
      </div>
    </section>
  )
}

export default AppointmentPage
