import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../css/appointment.css'
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
const Appointment = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    date: '',
    fullName: '',
    sex: '',
    dateOfBirth: '',
    age: '',
    nationality: '',
    address: '',
    phoneNo: '',
    email: '',
  })

  const [confirmation, setConfirmation] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
 const [recordNo, setRecordNo] = useState(null)

const recordNoGenerator = () => {
  const chart = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  let record = ''

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * chart.length)
    record += chart[randomIndex]
  }
  setRecordNo(record)
}
  useEffect(() => {
    recordNoGenerator()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      formData.name === '' ||
      !formData.address ||
      !formData.email ||
      !formData.phoneNo ||
      !formData.nationality ||
      !formData.age ||
      !formData.sex ||
      !formData.dateOfBirth
    ) {
      setConfirmation(
        <div id='success'>
          <p>Please fill in all required fields</p>
        </div>
      )
      return
    }
console.log(formData, recordNo);

    setIsLoading(true)
    try {
      const response = await fetch(
        'https://backend.ahspecialisthospital.com/api/reception',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ personalInfo: formData, recordNo }),
        }
      )

      if (response.ok) {
        setConfirmation(
          <div id='success'>
            <p>Appointment Booked successful!</p>
          </div>
        )
        setFormData({
          date: '',
          fullName: '',
          sex: '',
          dateOfBirth: '',
          age: '',
          nationality: '',
          address: '',
          phoneNo: '',
          email: '',
        })
        const { fullName } = formData
        const bookingDetails = {fullName, recordNo}
        navigate('/confirmation', { state: { bookingDetails } })
        setIsLoading(null)
      } else {
        setConfirmation(
          <div id='success'>
            <p>Appointment booking failed. Please try again later.</p>
          </div>
        )
      }
    } catch (error) {
      setConfirmation(
        <div id='success'>
          <p>
            An error occurred on the server. Please try again later or call us.
          </p>
        </div>
      )
    }
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }
  // const handleDateChange = (date, id) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [id]: date,
  //   }))
  //   console.log(date)
  // }
  if (isLoading) {
    <div className='overlay'>
      <div className='loader-dev'>
        <div class='lds-heart'>
          <div></div>
        </div>
      </div>
    </div>
  }
  return (
    <div className='appointment-container'>
      <div className='section-text'>
        <h1 className='section-heading appoint'>Book An Appointment</h1>
      </div>
      <div className='appointment-content'>
        <div className='appointment-text'>
          <p>
            Book your appointment online and save time with our easy-to-use
            scheduling system. Schedule your visit with ease. Our team is ready
            to assist you.
          </p>
        </div>
        <div className='notification'>
          {confirmation}
          <form action='' onSubmit={handleSubmit}>
            <div className='appoint-form'>
              <div className='input-form'>
                <label htmlFor='date'>Date</label>
                <input
                  type='date'
                  // placeholder='Eg: John Emon'
                  id='date'
                  value={formData.date}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor='fullName'>Full Name</label>
                <input
                  type='text'
                  placeholder='Eg: John Emon'
                  id='fullName'
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor='sex'>Gender:</label>
                <select
                  id='sex'
                  name='sex'
                  value={formData.sex}
                  onChange={handleInputChange}
                >
                  <option value='' disabled>
                    Select Gender
                  </option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </select>
              </div>
              <div className='input-form'>
                <label htmlFor='dateOfBirth'>Date Of Birth</label>
                <input
                  type='date'
                  placeholder='Eg: mm/dd/yyyy'
                  id='dateOfBirth'
                  value={formData.dateOfBirth}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor='age'>Age</label>
                <input
                  type='number'
                  placeholder='Eg: 21'
                  id='age'
                  value={formData.age}
                  onChange={handleInputChange}
                />
              </div>

              <div className='input-form'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  placeholder='Eg: email@gmail.com'
                  id='email'
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor='address'>Address</label>
                <input
                  id='address'
                  type='text'
                  placeholder='Eg: 60 Sam Brown'
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor='phoneNo'>Phone Number</label>
                <input
                  type='text'
                  placeholder='0800000000'
                  id='phoneNo'
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor='nationality'>Nationality</label>
                <input
                  type='text'
                  placeholder='Nationality'
                  id='nationality'
                  value={formData.nationality}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className='form-footer'>
              <button className='form-btn'>Book Appointment</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Appointment
