
import React, { useState } from 'react'
import '../css/service.css'
import { Link } from 'react-router-dom'

const Service = () => {
  return (
    
      <div className='service-container '>
        <div className='section-text'>
          <h1 className='section-heading'>Our Service</h1>
          <h3 className='section-subheading'>
            Explore Our Comprehensive Healthcare Services
          </h3>
          <p className='section-paragraph'>
            Discover a wide range of specialized medical services designed to
            meet your unique needs and improve your quality of life.
          </p>
        </div>
        <div className='service-container'>
          <div className='service-grid'>
            {services.map((service, index) => (
              <ServiceItem key={index} service={service} />
            ))}
          </div>
          <div className='s-btn'>
            <Link to='/service'>
              <button className='service-btn'>View All Services</button>
            </Link>
          </div>
        </div>
      </div>
    
  )
}

const ServiceItem = ({ service }) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleMouseEnter = () => {
    setIsFlipped(true)
  }

  const handleMouseLeave = () => {
    setIsFlipped(false)
  }
  const getFirstNWords = (str, n) => {
    const words = str.split(' ')
    return words.slice(0, n).join(' ')
  }
  return (
    <div
      className={`service-item ${isFlipped ? 'flipped' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='flip-card-inner'>
        <div className='flip-card-front'>
          <img src={service.img} alt={service.alt} />
          <h1>{service.title}</h1>
          <p>{getFirstNWords(service.paragraph, 8)}</p>
        </div>
        <div className='flip-card-back'>
          <img src={service.img} alt={service.alt} />
          <h1>{service.title}</h1>
          <p>{service.paragraph}</p>
        </div>
      </div>
    </div>
  )
}

const services = [
  {
    img: '/img/pharmacy_169837.png',
    alt: 'pharmacy',
    title: 'Pharmacy',
    paragraph:
      'Access quality medications and expert pharmaceutical care at our pharmacy. We are dedicated to providing good service and guidance, ensuring you receive the right medications and support for your health needs.',
  },
  {
    img: '/img/p8hhe1 1.png',
    alt: 'general-medical',
    title: 'General Medical Consultation',
    paragraph:
      'Receive personalized medical advice and treatment plans from our team of experienced physicians, dedicated to addressing your health concerns with care and expertise.',
  },
  {
    img: '/img/pregnancy-2495961-2090138 1.png',
    alt: 'ante-care',
    title: 'Obstetrics And Gynaecology',
    paragraph:
      "Embark on the journey of motherhood with confidence and care. Receive specialized care for women's health issues, from routine screenings to complex gynecological procedures.",
  },
  {
    img: '/img/surgery_13892747.png',
    alt: 'surgery',
    title: 'Surgery',
    paragraph:
      'Our surgery department is dedicated to providing comprehensive services, from routine procedures to complex surgeries. We are committed to ensuring your comfort, safety, and successful recovery throughout your surgical journey.',
  },
  {
    img: '/img/x-ray-computed-tomography-health-care-icon-png-favpng-V9sJ2SnucDQwNPE4bkqmszM9w-removebg-preview 1.png',
    alt: 'radiology',
    title: 'Radiology',
    paragraph:
      'Access advanced imaging services for accurate diagnosis and treatment planning, facilitated by our state-of-the-art radiology department.',
  },
  {
    img: '/img/laboratory_1682000.png',
    alt: 'laboratory',
    title: 'Laboratory and Diagnosis',
    paragraph:
      'Access reliable and timely diagnostic services through our advanced laboratory facilities, supporting accurate diagnosis and effective treatment plans.',
  },
]

export default Service
