import React from 'react'
import '../css/Blog.css'
const Blog = () => {
  return (
    <div className='blog-content'>
      <div className='section-text'>
        <h1 className='section-heading blue'>Our Blog</h1>
        <p className='section-paragraph blue'>
          Stay informed with our latest articles, health tips, and medical news
          from our team of experts
        </p>
      </div>
      <div className='blog-container'>
        <div className='blog-item'>
          <img src='/img/Surgeons 1.png' alt='surgery' />
          <h4>Bone health exercise : A guide to maintaining healthy bones</h4>
          <div className='blog-footerpost'>
            <div className='author'>
              <img src='/img/avarer.png' alt='' />
              <p>John Rock</p>
            </div>
            <div className='author'>
              <img src='\img\calender.png' alt='' />
              <p>14/04/2024</p>
            </div>
          </div>
        </div>
        <div className='blog-item'>
          <img src='/img/Surgeons 1.png' alt='surgery' />
          <h4>Bone health exercise : A guide to maintaining healthy bones</h4>
          <div className='blog-footerpost'>
            <div className='author'>
              <img src='/img/avarer.png' alt='' />
              <p>John Rock</p>
            </div>
            <div className='author'>
              <img src='\img\calender.png' alt='' />
              <p>14/04/2024</p>
            </div>
          </div>
        </div>
        <div className='blog-item'>
          <img src='/img/Surgeons 1.png' alt='surgery' />
          <h4>Bone health exercise : A guide to maintaining healthy bones</h4>
          <div className='blog-footerpost'>
            <div className='author'>
              <img src='/img/avarer.png' alt='' />
              <p>John Rock</p>
            </div>
            <div className='author'>
              <img src='\img\calender.png' alt='' />
              <p>14/04/2024</p>
            </div>
          </div>
        </div>
      </div>
      <div className='blog-btn'>View More</div>
    </div>
  )
}

export default Blog
