
import React, { useState } from 'react';
import axios from 'axios';

const TestimonialForm = () => {
  const [userName, setUserName] = useState('');
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('rating', rating);
    formData.append('message', message);
    formData.append('image', image);

    try {
      const res = await axios.post(
        'http://localhost:2020/api/testimonials',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Your Name"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        required
      />
      <input
        type="number"
        placeholder="Rating (1-5)"
        value={rating}
        onChange={(e) => setRating(e.target.value)}
        min="1"
        max="5"
        required
      />
      <textarea
        placeholder="Your Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <label htmlFor="file">Choose your image</label>
      <input
      name='file'
        type="file"
        onChange={(e) => setImage(e.target.files[0])}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default TestimonialForm;
